import React, { useEffect } from 'react'
import './subscribe.css'

// Helper to add scripts to our page
const insertScript = (src, id, parentElement) => {
  const script = window.document.createElement('script')
  script.async = true
  script.src = src
  script.id = id
  parentElement.appendChild(script)
  return script
}

// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}

const ORIGIN = {
  SUBSCRIBE_PAGE: "https://app.convertkit.com/forms/4004139/subscriptions",
  BLOG_POST: "https://app.convertkit.com/forms/1854814/subscriptions",
}

const Subscribe = ({ origin }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return
    }
    const document = window.document
    // In case our #commento container exists we can add our commento script
    if (document.getElementById('subscribe')) {
      insertScript(
        'https://f.convertkit.com/ckjs/ck.5.js',
        `convertkit-script`,
        document.body
      )
    }
    // Cleanup; remove the script from the page
    return () => removeScript(`convertkit-script`, document.body)
  }, [])

  return (
    <form
      action={ORIGIN[origin] ?? ORIGIN.BLOG_POST}
      style={{ backgroundColor: 'rgb(249, 250, 251)', borderRadius: '4px' }}
      className="seva-form formkit-form"
      method="post"
      data-sv-form="1854814"
      data-uid="a93c5a683d"
      data-format="inline"
      data-version="5"
      data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
      min-width="400 500 600 700 800"
    >
      <div style={{ opacity: 0.2 }} className="formkit-background"></div>
      <div data-style="minimal">
        <div
          className="formkit-header"
          style={{
            color: 'rgb(77, 77, 77)',
            fontSize: '27px',
            fontWeight: 700,
          }}
          data-element="header"
        >
          <h3 style={{ textAlign: 'center' }}>
            Join <em>Robin's Gazette</em>
          </h3>
        </div>
        <div
          className="formkit-subheader"
          style={{ color: 'rgb(104, 104, 104)', fontSize: '18px' }}
          data-element="subheader"
        >
          <p>Receive</p>
          <p>
            <strong>every second Sunday</strong>
          </p>
          <p>
            my <strong>favourite links</strong> and <strong>findings</strong>{' '}
          </p>
          <p>
            on<strong> frontend</strong> technologies, <strong>writing</strong>{' '}
            and more!
          </p>
          <p>​</p>
          <p>
            <em>52 issues and counting since December, 2020</em>
          </p>
        </div>
        <ul
          className="formkit-alert formkit-alert-error"
          data-element="errors"
          data-group="alert"
        ></ul>
        <div
          data-element="fields"
          data-stacked="true"
          className="seva-fields formkit-fields"
        >
          <div className="formkit-field">
            <input
              className="formkit-input"
              aria-label="First Name"
              style={{
                color: 'rgb(0, 0, 0)',
                borderColor: 'rgb(227, 227, 227)',
                borderRadius: '4px',
                fontWeight: 400,
              }}
              name="fields[first_name]"
              placeholder="First Name"
              type="text"
            />
          </div>
          <div className="formkit-field">
            <input
              className="formkit-input"
              name="email_address"
              style={{
                color: 'rgb(0, 0, 0)',
                borderColor: 'rgb(227, 227, 227)',
                borderRadius: '4px',
                fontWeight: 400,
              }}
              aria-label="Email Address"
              placeholder="Email Address"
              required
              type="email"
            />
          </div>
          <button
            data-element="submit"
            className="formkit-submit formkit-submit"
            style={{
              color: 'rgb(255, 255, 255)',
              backgroundColor: 'rgb(33, 132, 85)',
              borderRadius: '4px',
              fontWeight: 700,
            }}
          >
            <div className="formkit-spinner">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span className="">Subscribe</span>
          </button>
        </div>
        <div
          className="formkit-guarantee"
          style={{
            color: 'rgb(77, 77, 77)',
            fontSize: '13px',
            fontWeight: 400,
          }}
          data-element="guarantee"
        >
          <p>I hate spam, don't expect any from me. Unsubscribe at any time.</p>
        </div>
        <div className="formkit-powered-by-convertkit-container">
          <a
            href="https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"
            data-element="powered-by"
            className="formkit-powered-by-convertkit"
            data-variant="dark"
            target="_blank"
            rel="noopener noreferrer"
          >
            Built with ConvertKit
          </a>
        </div>
      </div>
    </form>
  )
}

export default Subscribe
